<template>
  <div class="note">
    <!-- general columns -->
    <div v-if="editMode == 'editing'" class="columns">
      <div class="col-2 col-xl-3 col-lg-12">
        <Dropdown
          class="iconDropdown"
          :options="mapIcons"
          :settings="iconSetting"
          v-model="note.iconId"
          :class="{ bad: badIcon == true }"
        />

        <!-- language dropdown -->
        <Dropdown
          class="languageDropdown"
          :options="mapLanguages"
          :settings="languageSetting"
          v-model="note.languageId"
          :class="{ bad: badLanguage == true }"
        />

        <!-- buttons -->
        <div class="buttonPanel">
          <button class="genericButton saveButton" @click="saveNote()">
            Save
          </button>
          <button class="genericButton cancelButton" @click="goBack()">
            Cancel
          </button>
        </div>
        <div class="termsPanel">
          By submitting changes you agree to the
          <router-link :to="{ name: 'Terms' }" class="termsLink" target="_blank"
            >Terms of Service</router-link
          >
        </div>
      </div>
      <div class="col-10 col-xl-9 col-lg-12 right-col">
        <!-- title -->
        <input
          v-model="note.title"
          class="title"
          placeholder="Note title"
          :class="{ badTitle: badTitle == true }"
        />

        <!-- Actual code -->
        <div class="mirrorPanel">
          <codemirror
            v-model="note.note"
            :options="getOptions"
            :class="{ bad: badCode == true }"
          ></codemirror>
        </div>
      </div>
    </div>

    <!-- saving spinner -->
    <div v-if="editMode == 'saving'" class="savingPanel">
      <i class="fas fa-cog fa-spin"></i>
      <div>
        Saving
      </div>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import { mapState } from "vuex";
import Dropdown from "@/components/Dropdown";

export default {
  components: { codemirror, Dropdown },
  name: "NoteEditor",
  props: {
    note: {},
  },
  data() {
    return {
      languageSetting: {
        placeholder: "Language",
      },
      iconSetting: {
        placeholder: "<i class='fas fa-question-circle'></i>",
        iconMode: true,
      },
      editMode: "editing",
      badIcon: false,
      badLanguage: false,
      badTitle: false,
      badCode: false,
    };
  },
  created() {
    this.editMode = "editing";
  },
  computed: {
    // language type for codemirror
    getMode() {
      let setting = this.languages.find((i) => {
        return i.id === this.note.languageId;
      });
      return setting == undefined ? "" : setting.mime;
    },
    getTheme() {
      if (this.getMode == "text/x-java") {
        return "one-dark-java";
      }
      return "one-dark";
    },
    // codemirror options
    getOptions() {
      return {
        tabSize: 4,
        mode: this.getMode,
        theme: this.getTheme,
        lineNumbers: false,
        line: true,
        scrollbarStyle: "overlay",
        coverGutterNextToScrollbar: false,
        readOnly: false,
      };
    },
    // options for language dropdown
    mapLanguages() {
      return this.languages.map((lang) => {
        let selectOption = {};
        selectOption.id = lang.id;
        selectOption.name = lang.name;
        return selectOption;
      });
    },
    // options for icon settings
    mapIcons() {
      return this.icons.map((icon) => {
        let selectOption = {};
        selectOption.id = icon.id;
        selectOption.name = "<i class='" + icon.css + "'></i>";
        selectOption.tags = icon.title;
        return selectOption;
      });
    },
    ...mapState(["languages", "icons", "copied_noteKey", "linked_noteKey"]),
  },
  methods: {
    // going back to the listing (or single page view)
    goBack() {
      this.$router.go(-1);
    },
    // saving the note button clicked
    saveNote() {
      // validation has to pass
      if (this.validate() == false) {
        return;
      }
      // otherwise submit
      else {
        this.editMode = "saving";
        this.$store.dispatch("saveNote", { note: this.note });
      }
    },
    validate() {
      let ok = true;
      this.badCode = false;
      this.badTitle = false;
      this.badIcon = false;
      this.badLanguage = false;

      // not enough code?
      if (this.note.note == undefined || this.note.note.length < 15) {
        this.badCode = true;
        ok = false;
      }

      // no title?
      if (this.note.title == undefined || this.note.title.length < 5) {
        this.badTitle = true;
        ok = false;
      }

      // no icon?
      if (this.note.iconId == undefined) {
        this.badIcon = true;
        ok = false;
      }

      // no language
      if (this.note.languageId == undefined) {
        this.badLanguage = true;
        ok = false;
      }
      return ok;
    },
  },
};
</script>

<style scoped>
.note {
  position: relative;
  background-color: #282e35;
  padding: 60px 100px;
  border-radius: 17px;
  margin-bottom: 80px;
  background: rgb(40, 46, 53);
  background: linear-gradient(
    180deg,
    rgba(40, 46, 53, 1) 0%,
    rgba(35, 40, 47, 1) 100%
  );
}

/* at less than 800, have smaller padding in notes */
@media (max-width: 960px) {
  .note {
    padding: 60px 20px;
  }
}

@media (max-width: 960px) {
  .buttonPanel {
    display: flex;
    justify-content: center;
  }
  .buttonPanel button {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.right-col {
  padding-left: 70px;
}
@media (max-width: 400px) {
  .right-col {
    padding-left: 5px;
  }
}

.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
  padding-top: 22px;
  color: #bfcad7;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px dashed #353c41;
  padding-bottom: 13px;
}
.title::placeholder {
  color: #505a61;
}

.mirrorPanel {
  padding-top: 25px;
}

.languageDropdown {
  margin-bottom: 50px;
}

.cancelButton,
.saveButton {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  margin-bottom: 20px;
}

.cancelButton {
  background-color: #505a61;
}

.savingPanel {
  text-align: center;
  color: #505a61;
  font-size: 22px;
  font-weight: bold;
}
.savingPanel .fas {
  font-size: 120px;
  padding: 50px;
}

.termsPanel {
  padding-top: 20px;
  color: #505a61;
  text-align: center;
  font-size: 13px;
}

.termsLink {
  text-decoration: underline !important;
  cursor: pointer;
  color: #606d76;
}

.badTitle {
  background-color: #c72f2f;
  border-radius: 10px;
  color: #802424;
}

.badTitle::placeholder {
  color: #802424;
}
</style>
