<template>
  <div class="note">
    <!-- Actions menu -->
    <div class="actionsPanel">
      <i
        class="fas fa-copy"
        @click="copyToClipboard()"
        :class="{ copied: copied_noteKey === note.noteKey }"
      ></i>
      <i
        class="fas fa-link"
        @click="copyLink()"
        :class="{ copied: linked_noteKey === note.noteKey }"
      ></i>
      <i class="fas fa-edit" @click="editNote()"></i>
    </div>

    <!-- Title -->
    <router-link
      :to="{
        name: 'OneNote',
        params: { notekey: note.noteKey },
      }"
    >
      <div class="title-panel">
        <!-- Icon -->
        <div class="title-icon">
          <i :class="iconCss"></i>
        </div>

        <!-- Title label -->
        <div class="title-text">{{ note.title }}</div>
      </div>
    </router-link>
    <!-- Actual code -->
    <div class="mirrorPanel">
      <codemirror v-model="note.note" :options="getOptions"></codemirror>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import { mapState } from "vuex";

export default {
  components: { codemirror },
  name: "Note",
  props: {
    note: {},
  },
  data() {
    return {};
  },
  computed: {
    // find our icon CSS from the store's profile
    iconCss() {
      let setting = this.icons.find((i) => {
        return i.id === this.note.iconId;
      });
      return setting == undefined ? "" : setting.css;
    },
    getMode() {
      let setting = this.languages.find((i) => {
        return i.id === this.note.languageId;
      });
      return setting == undefined ? "" : setting.mime;
    },
    getTheme() {
      if (this.getMode == "text/x-java") {
        return "one-dark-java";
      }
      return "one-dark";
    },
    getOptions() {
      return {
        tabSize: 4,
        mode: this.getMode,
        theme: this.getTheme,
        lineNumbers: false,
        line: true,
        scrollbarStyle: "overlay",
        coverGutterNextToScrollbar: false,
        readOnly: true,
      };
    },
    ...mapState(["languages", "icons", "copied_noteKey", "linked_noteKey"]),
  },
  methods: {
    // copy note contents to clipboard
    copyToClipboard() {
      // remember the note key that was copied
      this.$store.dispatch("setCopiedNoteKey", {
        copied_noteKey: this.note.noteKey,
      });

      // actual copy
      this.$clipboard(this.note.note);
    },
    // copy the note URL to clipboard
    copyLink() {
      // remember the note key that was copied
      this.$store.dispatch("setLinkedNoteKey", {
        linked_noteKey: this.note.noteKey,
      });

      // actual copy
      this.$clipboard("https://www.membug.com/note/" + this.note.noteKey);
    },
    editNote() {
      this.$router.push({
        name: "Edit",
        params: { notekey: this.note.noteKey },
      });
    },
  },
};
</script>

<style scoped>
.note {
  position: relative;
  padding: 60px 100px;
  border-radius: 17px;
  margin-bottom: 80px;
  padding-bottom: 100px;
  background: rgb(40, 46, 53);
  background: linear-gradient(
    180deg,
    rgba(40, 46, 53, 1) 0%,
    rgba(35, 40, 47, 1) 100%
  );
}

/* at less than 800, have smaller padding in notes */
@media (max-width: 800px) {
  .note {
    padding: 30px 50px;
  }
}

/* at less than 400, utilize all the sapce we got */
@media (max-width: 800px) {
  .note {
    padding: 30px 10px;
  }
}

/* Note title panel + icon + text */
.title-panel {
  display: flex;
  cursor: pointer;
}
.title-icon {
  flex: 0 0 100px;
  font-size: 53px;
  color: #b6c1ce;
}
.title-text {
  flex: 1;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
  padding-top: 22px;
  color: #bfcad7;
  word-break: break-word;
}

/* Note title + icon on separte lines for very narrow screens */
@media (max-width: 800px) {
  .title-panel {
    display: flex;
    flex-direction: column;
  }
  .title-icon {
    text-align: center;
    flex: 0 0 80px;
  }
  .title-text {
    text-align: center;
    padding-top: 5px;
  }
}

/* Note title + icon on separte lines for very narrow screens */
@media (max-width: 400px) {
  .title-panel {
    display: flex;
    flex-direction: column;
  }
  .title-icon {
    text-align: center;
    flex: 0 0 80px;
  }
  .title-text {
    text-align: center;
    padding-top: 5px;
    font-size: 21px;
    word-break: break-word;
  }
}

.mirrorPanel {
  padding-top: 25px;
}

/* Actions */
.actionsPanel {
  background-color: #242a30;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 10px 22px;
  border-radius: 15px;
  font-size: 22px;
  visibility: hidden;
  color: #666f79;
}

.note:hover .actionsPanel {
  visibility: visible;
}

/* action icons */
.actionsPanel .fas {
  padding-right: 20px;
  cursor: pointer;
}

.actionsPanel .fas:last-child {
  padding-right: 0px;
}

.actionsPanel .fas:hover {
  color: #b6c1ce;
}

.copied {
  color: #bce698;
}
</style>
