<template>
  <div>
    <!-- back to listing panel -->
    <div class="columns">
      <div class="column col-5 col-xl-4 col-md-2"></div>
      <div
        class="column col-2 col-xl-4  col-md-8 go-back-panel"
        @click="backToListing()"
      >
        <i class="fas fa-layer-group"></i>
        Back to listing
      </div>
      <div class="column col-5 col-xl-4 col-md-2"></div>
    </div>

    <!-- actual note -->
    <div class="columns">
      <!-- left padding -->
      <div class="column col-1"></div>

      <!-- signle column mode -->
      <div class="column col-10 single-note-panel">
        <!-- Note iteself -->
        <Note v-if="singleNote" :note="singleNote" />
      </div>

      <!-- right padding -->
      <div class="column col-1"></div>
    </div>
  </div>
</template>

<script>
import Note from "@/components/Note";
import { mapState } from "vuex";

export default {
  name: "OneNote",
  props: ["notekey"],
  components: { Note },
  data() {
    return {
      note: {},
    };
  },
  computed: {
    temp() {
      return {};
    },
    ...mapState(["singleNote", "searchTerm", "listing_visited"]),
  },
  created() {
    this.$store.dispatch("getSingleNote", { id: this.notekey });
    window.scrollTo(0, 0);
  },
  methods: {
    backToListing() {
      // if we didn't come from the listing, just go to the top of the listing
      if (this.listing_visited == false) {
        this.$router.push({ name: "Listing" });
      }
      // simply go back to the listing with the right scroll
      else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.go-back-panel {
  color: #505a61;
  text-align: center;
  background-color: #252b31;
  border-radius: 60px;
  height: 60px;
  line-height: 60px;
  margin-top: 44px;
  margin-bottom: 44px;
  cursor: pointer;
}

.go-back-panel:hover {
  color: #b6c1ce;
}

.go-back-panel .fas {
  font-size: 34px;
  vertical-align: middle;
  padding-right: 19px;
}
</style>
