import router from "@/router/router.js";
import LoginService from "@/service/LoginService";
import ContactService from "@/service/ContactService";

export const namespaced = true;

export const state = {
  loggedIn: false,
  loginFailed: false,
  contactKey: undefined,
  contactResult: undefined,
};

export const mutations = {
  SET_LOGGED_IN(state, loggedIn) {
    state.loggedIn = loggedIn;
  },
  SET_LOGIN_FAILED(state, loginFailed) {
    state.loginFailed = loginFailed;
  },
  SET_CONTACT_KEY(state, contactKey) {
    state.contactKey = contactKey;
  },
  CONTACT_RESULT(state, contactResult) {
    state.contactResult = contactResult;
  },
};

export const actions = {
  // are we logged in on the server side?
  checkLogin({ commit }) {
    return LoginService.checkIfWeAreLoggedIn()
      .then((response) => {
        // remember what happened
        let success = response.data === 0 ? false : true;
        commit("SET_LOGGED_IN", success);
      })
      .catch((err) => console.log(err));
  },

  // are we logged in on the server side?
  attemptLogin({ commit }, { attempt }) {
    return LoginService.attemptToLogin(attempt)
      .then((response) => {
        // remember what happened
        let success = response.data === 0 ? false : true;
        commit("SET_LOGIN_FAILED", success == false);
        commit("SET_LOGGED_IN", success);

        // proceed to changes page
        if (success) {
          router.push({ name: "Changes" });
        }
      })
      .catch((err) => console.log(err));
  },

  // get contact key
  getContactKey({ commit }) {
    ContactService.geKey().then((response) => {
      commit("SET_CONTACT_KEY", response.data);
    });
  },
  // submit the message
  submitMessage({ commit }, { email, message, key }) {
    ContactService.submitMessage(email, message, key).then((response) => {
      commit("CONTACT_RESULT", response.data);
    });
  },
  // clear the message status
  clearMessageStatus({ commit }) {
    commit("CONTACT_RESULT", undefined);
  },
};

export const getters = {
  isLoggedIn: (state) => {
    return state.loggedIn;
  },
};
