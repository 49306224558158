import Vue from "vue";
import VueRouter from "vue-router";
import Listing from "@/views/Listing.vue";
import OneNote from "@/views/OneNote.vue";
import Edit from "@/views/Edit.vue";
import Changes from "@/views/Changes.vue";
import Login from "@/views/Login.vue";
import store from "@/store/store.js";
import Terms from "@/views/Terms.vue";
import Contact from "@/views/Contact.vue";
import SiteMap from "@/views/SiteMap.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Listing",
    component: Listing,
  },
  {
    path: "//",
    redirect: "/",
  },
  {
    path: "///",
    redirect: "/",
  },
  {
    path: "/notes",
    redirect: { name: "Listing" },
  },
  {
    path: "/note/:notekey",
    name: "OneNote",
    component: OneNote,
    props: true,
  },
  {
    path: "/edit/:notekey",
    name: "Edit",
    component: Edit,
    props: true,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/support",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/changes",
    name: "Changes",
    component: Changes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth",
    name: "Login",
    component: Login,
  },
  {
    path: "/sitemap",
    name: "SiteMap",
    component: SiteMap,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  // if we need auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if not logged in, see login page
    if (store.getters["login/isLoggedIn"] == false) {
      next({ name: "Login" });
    }
    // otherwise go through
    else {
      next();
    }
  }
  // if we don't need auth
  else {
    next();
  }
});

export default router;
