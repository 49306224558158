<template>
  <div>
    <div v-if="showNav" class="navbar columns">
      <div class="column col-1"></div>
      <div class="column col-10">
        <div class="navbar-inner columns">
          <!-- logo -->
          <div class="nav-item column col-4 col-md-6 item-logo md-order-1">
            <img
              class="logo"
              src="../assets/logo.png"
              @click="logoClick()"
              alt="logo"
            />
          </div>

          <!-- search -->
          <div
            class="nav-item column col-4 col-md-12 item-search col-md-order-3"
          >
            <Search />
          </div>

          <!-- column + new button -->
          <div
            class="nav-item column col-4 col-md-6 item-add col-md-order-2 rightNavPart"
          >
            <!-- single column mode -->
            <i
              v-if="narrow_page == false && this.$route.path == '/'"
              class="fas fa-th-list colControl"
              :class="{ colControlSelected: user_choice == 'single' }"
              @click="selectUserColumnPreference('single')"
            ></i>

            <!-- double column mode -->
            <i
              v-if="narrow_page == false && this.$route.path == '/'"
              class="fas fa-th-large colControl"
              :class="{ colControlSelected: user_choice == 'double' }"
              @click="selectUserColumnPreference('double')"
            ></i>
            <button class="addButton" @click="addClicked()">Add</button>
          </div>
        </div>
      </div>
      <div class="column col-1"></div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search";
import { mapState } from "vuex";

export default {
  name: "NavBar",
  components: { Search },
  data() {
    return {
      showNav: true,
    };
  },
  watch: {
    "$route.path": function() {
      this.checkIfNeedToShow();
    },
  },
  created() {
    this.checkIfNeedToShow();
  },
  computed: {
    // should be show the nav bar?
    test123() {
      return true;
    },
    ...mapState({
      notes: (state) => state.notes,
      col_mode: (state) => state.columns.col_mode,
      searchTerm: (state) => state.searchTerm,
      narrow_page: (state) => state.columns.narrow_page,
      user_choice: (state) => state.columns.user_choice,
    }),
  },
  methods: {
    checkIfNeedToShow() {
      // don't show nav for admin fatures
      if (
        //this.$router.currentRoute.name == "Changes" ||
        this.$router.currentRoute.name == "Login"
      ) {
        this.showNav = false;
      }
      // otherwise make it visible
      else {
        this.showNav = true;
      }
    },

    // select our options as a user, will be overwritten if needed
    selectUserColumnPreference(selection) {
      // trim the notes
      this.$store.dispatch("trimNotes").then(
        // set the preference
        this.$store.dispatch("columns/setUserChoice", {
          user_choice: selection,
        })
      );
    },

    // search blank
    clickLogo() {
      this.$store.dispatch("search", {
        searchTerm: "",
        startIndex: 0,
      });
    },

    // add new clicked
    addClicked() {
      this.$router.push({
        name: "Edit",
        params: { notekey: "new" },
      });
    },

    // clicked on logo
    logoClick() {
      // if we are already here, just search from scratch
      if (this.$router.currentRoute.name == "Listing") {
        this.$store.dispatch("search", {
          searchTerm: "",
          startIndex: 0,
        });
      }
      // otherwise clear notes, navigate over and pull up all notes
      else {
        this.$store
          .dispatch("clearNotes")
          .then(this.$router.push({ name: "Listing" }))
          .then(
            this.$store.dispatch("search", {
              searchTerm: "",
              startIndex: 0,
            })
          );
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #272d34;
}
.navbar,
.navbar-inner {
  min-height: 118px;
}
.nav-item {
  display: flex;
  align-items: center;
}
.logo {
  padding-left: 75px;
  cursor: pointer;
}

@media (max-width: 840px) {
  .logo {
    padding-left: 10px;
    padding-top: 20px;
    cursor: pointer;
  }
}

.rightNavPart {
  justify-content: flex-end;
}

.colControl {
  font-size: 28px;
  color: #505a61;
  margin-top: 20px;
  margin-right: 15px;
  cursor: pointer;
}
.colControlSelected {
  color: #96a0ac;
}

.addButton {
  background-color: #8ab06b;
  border: none;
  color: white;
  padding: 11px 40px;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
  outline: none;
}
</style>
