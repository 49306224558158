import axios from "axios";

const apiClient = axios.create({
  //   baseURL: "http://localhost:8080/membug/",
  baseURL: "https://www.membug.com/membug/",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  // attempt to login
  attemptToLogin(attempt) {
    return apiClient.post("/auth", attempt);
  },
  // check if we are logged in.
  checkIfWeAreLoggedIn() {
    return apiClient.get("/check");
  },
};
