import ChangeService from "@/service/ChangeService";

export const namespaced = true;

export const state = {
  changes: [],
};

export const mutations = {
  SET_CHANGES(state, changes) {
    state.changes = changes;
  },
};

export const actions = {
  // get all the changes
  fetchChanges({ commit }) {
    return ChangeService.getChanges()
      .then((response) => {
        commit("SET_CHANGES", response.data);
      })
      .catch((err) => console.log(err));
  },
  // accept change
  accept({ commit }, { id }) {
    return ChangeService.accept(id)
      .then(() => {
        let filtered = state.changes.filter((change) => change.id != id);
        commit("SET_CHANGES", filtered);
      })
      .catch((err) => console.log(err));
  },
  // reject change
  reject({ commit }, { id }) {
    return ChangeService.reject(id)
      .then(() => {
        let filtered = state.changes.filter((change) => change.id != id);
        commit("SET_CHANGES", filtered);
      })
      .catch((err) => console.log(err));
  },
};
