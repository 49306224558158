import axios from "axios";

const apiClient = axios.create({
  //   baseURL: "http://localhost:8080/membug/",
  baseURL: "https://www.membug.com/membug/",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  // get the auth key to allow us to submit notes
  geKey() {
    return apiClient.get("/contactKey");
  },

  // submit the actual message
  submitMessage(email, message, key) {
    return apiClient.post("/contact", {
      email: email,
      message: message,
      key: key,
    });
  },
};
