<template>
  <div>
    <!-- actual note -->
    <div class="columns">
      <!-- left padding -->
      <div class="column col-1"></div>

      <!-- signle column mode -->
      <div class="column col-10 changesPanel">
        <!-- Note iteself -->

        <Change
          v-for="change in changes"
          :key="change.id"
          :note="change"
        ></Change>

        <!-- <Note v-if="singleNote" :note="singleNote" /> -->
      </div>

      <!-- right padding -->
      <div class="column col-1"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Change from "@/components/Change";

export default {
  name: "Changes",
  components: {
    Change,
  },
  created() {
    this.$store.dispatch("changes/fetchChanges");
  },
  computed: {
    hmm() {
      return false;
    },
    ...mapState({
      changes: (state) => state.changes.changes,
    }),
  },
};
</script>

<style scoped>
.changesPanel {
  margin-top: 100px;
}
</style>
