var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dropdownWidget",class:{ openField: _vm.open == true }},[_c('div',{staticClass:"selectedValuePanel",class:{
        'selectedValuePanel-icon-mode':
          _vm.settings !== undefined && _vm.settings.iconMode == true,
      },on:{"click":function($event){return _vm.fieldClick()}}},[(_vm.selectedOptionText !== undefined)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedOptionText)}}):(
          _vm.selectedOptionText == undefined &&
            _vm.settings !== undefined &&
            _vm.settings.placeholder !== undefined
        )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.settings.placeholder)}}):_c('span',[_vm._v("Specify")]),_c('i',{staticClass:"fas fa-angle-down"})]),(_vm.open == true)?_c('div',{staticClass:"dropdownPanel",class:{
        'dropdownPanel-icon-mode':
          _vm.settings !== undefined && _vm.settings.iconMode == true,
      }},[_c('div',{staticClass:"searchPanel"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typed),expression:"typed"}],ref:"searchBox",staticClass:"searchBox",attrs:{"type":"text"},domProps:{"value":(_vm.typed)},on:{"keydown":_vm.searchKeyDown,"input":function($event){if($event.target.composing){ return; }_vm.typed=$event.target.value}}}),_c('i',{staticClass:"fas fa-search searchIcon"}),(_vm.typed.length > 0)?_c('i',{staticClass:"fas fa-times closeIcon",on:{"click":function($event){return _vm.clearSearch()}}}):_vm._e()]),_c('vue-custom-scrollbar',{staticClass:"optionsPanel",class:{
          'optionsPanel-icon-mode':
            _vm.settings !== undefined && _vm.settings.iconMode == true,
        }},_vm._l((_vm.optionList),function(opt,index){return _c('div',{key:opt.id,staticClass:"option",class:{
            'option-selected': index == _vm.selectedIndex,
            'option-icon-mode':
              _vm.settings !== undefined && _vm.settings.iconMode == true,
          },domProps:{"innerHTML":_vm._s(opt.name)},on:{"mouseover":function($event){return _vm.hoverOption(index)},"click":function($event){return _vm.clickOnOption(opt)}}})}),0)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }