import axios from "axios";

const apiClient = axios.create({
  // baseURL: "http://localhost:8080/membug/",
  baseURL: "https://www.membug.com/membug/",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getLanguages() {
    return apiClient.get("/languages");
  },
  getIcons() {
    return apiClient.get("/icons");
  },
  search(term, startIndex) {
    return apiClient.get(
      "/search?searchTerms=" + term + "&startIndex=" + startIndex
    );
  },
  getSingleNote(id) {
    return apiClient.get("/note?id=" + id);
  },
  saveNote(note) {
    return apiClient.post("/change", note);
  },
  getListing() {
    return apiClient.get("/listing");
  },
};
