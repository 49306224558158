<template>
  <div>
    <!-- actual note -->
    <div class="columns">
      <!-- left padding -->
      <div class="column col-3 col-xl-1 col-sm-1"></div>

      <!-- single column mode -->
      <div class="column col-6  col-xl-10 col-sm-10">
        <!-- the contact form -->
        <div v-if="contactResult == undefined" class="genericPanel">
          <!-- title -->
          <div class="genericTitle">Contact</div>
          <!-- email -->
          <div class="fieldPanel" ref="emailWrapper">
            <input
              v-model="email"
              type="text"
              class="field"
              placeholder="email@website"
              @keydown="typedSomething"
            />
            <i class="fas fa-envelope"></i>
          </div>
          <!-- note -->
          <div class="fieldPanel" ref="noteWrapper">
            <textarea
              v-model="note"
              class="field fieldTextArea"
              placeholder="Message..."
              @keydown="typedSomething"
            />
            <i class="fas fa-comment"></i>
          </div>
          <button class="genericButton" @click="submit()">Submit</button>
        </div>

        <!-- submitted -->
        <div v-if="contactResult === 1" class="genericPanel">
          <i class="bigIcon fas fa-check-circle"></i>
          Message sent
          <button class="genericButton backButton" @click="backToListing">
            Back to listing
          </button>
        </div>

        <!-- not submitted -->
        <div v-if="contactResult === 0" class="genericPanel">
          <i class="bigIcon badIcon fas fa-minus-circle"></i>
          <span class="badLabel">
            Message blocked
          </span>
          <button class="genericButton backButton" @click="backToListing">
            Back to listing
          </button>
        </div>
      </div>

      <!-- right padding -->
      <div class="column col-3 col-xl-2 col-sm-1"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Contact",
  data() {
    return {
      email: "",
      note: "",
      validationSuccess: undefined,
    };
  },
  created() {
    this.$store.dispatch("login/clearMessageStatus");
    setTimeout(() => this.$store.dispatch("login/getContactKey"), 2000);
  },
  computed: {
    something() {
      return true;
    },
    ...mapState({
      contactKey: (state) => state.login.contactKey,
      contactResult: (state) => state.login.contactResult,
    }),
  },
  methods: {
    // go back to the main page
    backToListing() {
      this.$router.push({ name: "Listing" });
    },
    typedSomething(e) {
      // enter key, try to login
      if (e.keyCode == 13) {
        this.submit();
        return;
      }
      // otherwise see if we need to validate
      // only if the validation failed in the past, will we validate after typing
      if (this.validationSuccess == false) {
        this.validate();
      }
    },
    validate() {
      let ok = true;

      // remove all
      this.$refs.emailWrapper.classList.remove("vfail");
      this.$refs.noteWrapper.classList.remove("vfail");

      // login
      if (this.email.length < 3 || this.validateEmail(this.email) == false) {
        this.$refs.emailWrapper.classList.add("vfail");
        ok = false;
      }
      //password
      if (this.note.length < 3) {
        this.$refs.noteWrapper.classList.add("vfail");
        ok = false;
      }
      this.validationSuccess = ok;
      return ok;
    },
    submit() {
      // is the form ok?
      if (this.validate() == false) {
        return;
      }
      // submit
      this.$store.dispatch("login/submitMessage", {
        email: this.email,
        message: this.note,
        key: this.contactKey,
      });
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style scoped>
/* at less than 800, have smaller padding in notes */
@media (max-width: 960px) {
  .contactPanel {
    padding: 60px 40px;
  }
}

.genericTitle {
  padding-bottom: 40px;
}

.fieldTextArea {
  line-height: 20px;
  height: 200px;
  padding-top: 17px;
  border-radius: 20px;
}

/* things are bad panel */
.badLabel {
  color: #ce4747;
  font-size: 24px;
}

/* status icon */
.bigIcon {
  color: #8ab06b;
  font-size: 120px;
  padding-bottom: 30px;
}

/* bad icon*/
.badIcon {
  color: #6e7c87;
}

.explain {
  color: #798892;
}

/* everything is good, go back to listing */
.backButton {
  color: #b4c0c7;
  background-color: #353d46;
  margin-left: 0px;
  width: 200px;
  margin-top: 66px;
}
</style>
