<template>
  <div class="note">
    <!-- general columns -->
    <div class="columns">
      <div class="col-1 col-xl-3 col-lg-12">
        <!-- note icon -->
        <i :class="iconCss" class="finalIcon"></i>

        <!-- buttons -->
        <div class="buttonPanel">
          <button class="genericButton acceptButton" @click="accept()">
            Accept
          </button>
          <button class="genericButton rejectButton" @click="reject()">
            Reject
          </button>
        </div>
      </div>
      <div class="col-11 col-xl-9 col-lg-12 right-col">
        <!-- title -->
        <input v-model="note.title" class="title" />

        <!-- Actual code -->
        <div class="mirrorPanel">
          <codemirror v-model="note.note" :options="getOptions"></codemirror>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import { mapState } from "vuex";

export default {
  components: { codemirror },
  name: "Change",
  props: {
    note: {},
  },
  data() {
    return {};
  },
  computed: {
    iconCss() {
      let setting = this.icons.find((i) => {
        return i.id === this.note.iconId;
      });
      return setting == undefined ? "" : setting.css;
    },
    // language type for codemirror
    getMode() {
      let setting = this.languages.find((i) => {
        return i.id === this.note.languageId;
      });
      return setting == undefined ? "" : setting.mime;
    },
    getTheme() {
      if (this.getMode == "text/x-java") {
        return "one-dark-java";
      }
      return "one-dark";
    },
    // codemirror options
    getOptions() {
      return {
        tabSize: 4,
        mode: this.getMode,
        theme: this.getTheme,
        lineNumbers: false,
        line: true,
        scrollbarStyle: "overlay",
        coverGutterNextToScrollbar: false,
        readOnly: true,
      };
    },
    ...mapState(["languages", "icons"]),
  },
  methods: {
    accept() {
      this.$store.dispatch("changes/accept", { id: this.note.id });
    },
    reject() {
      this.$store.dispatch("changes/reject", { id: this.note.id });
    },
  },
};
</script>

<style scoped>
.note {
  position: relative;
  background-color: #282e35;
  padding: 60px 100px;
  border-radius: 17px;
  margin-bottom: 80px;
  background: rgb(40, 46, 53);
  background: linear-gradient(
    180deg,
    rgba(40, 46, 53, 1) 0%,
    rgba(35, 40, 47, 1) 100%
  );
}

/* at less than 800, have smaller padding in notes */
@media (max-width: 960px) {
  .note {
    padding: 60px 20px;
  }
}

@media (max-width: 960px) {
  .buttonPanel {
    display: flex;
    justify-content: center;
  }
  .buttonPanel button {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.right-col {
  padding-left: 70px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
  padding-top: 22px;
  color: #bfcad7;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px dashed #353c41;
  padding-bottom: 13px;
}

.acceptButton,
.rejectButton {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  margin-bottom: 20px;
}

.rejectButton {
  background-color: #a24343;
}

.mirrorPanel {
  padding-top: 25px;
}

.finalIcon {
  font-size: 70px;
  text-align: center;
  width: 100%;
  padding-bottom: 60px;
  padding-top: 10px;
}
</style>
