<template>
  <div>
    <!-- actual listing -->
    <div class="listing-panel columns">
      <!-- left padding -->
      <div class="column col-1"></div>

      <!-- signle column mode -->
      <div v-if="col_mode == 'single'" class="column col-10">
        <!-- Note template -->
        <div div v-for="note in notes" :key="note.id" class>
          <Note :note="note" />
        </div>
      </div>

      <!-- double col ONE-->
      <div v-if="col_mode !== 'single'" class="column col-5 col-padding-rule">
        <!-- Note template -->
        <div div v-for="(note, index) in notes" :key="note.id" class>
          <Note v-if="index % 2 == 0" :note="note" />
        </div>
      </div>
      <!-- double col TWO -->
      <div v-if="col_mode !== 'single'" class="column col-5 col-padding-rule">
        <!-- Note template -->
        <div div v-for="(note, index) in notes" :key="note.id" class>
          <Note v-if="index % 2 == 1" :note="note" />
        </div>
      </div>

      <!-- right padding -->
      <div class="column col-1"></div>
    </div>

    <!-- nothing found -->
    <div
      v-if="searchTerm.length > 0 && notes.length == 0"
      class="nothingFoundPanel"
    >
      <i class="fas fa-skull"></i>
      <div>
        Nothing found
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/base16-dark.css";
import Note from "@/components/Note";

export default {
  name: "Listing",
  components: {
    Note,
  },
  mounted() {
    this.$store.dispatch("visitedListing");
  },
  computed: {
    temp() {
      return {};
    },
    ...mapState({
      notes: (state) => state.notes,
      col_mode: (state) => state.columns.col_mode,
      searchTerm: (state) => state.searchTerm,
    }),
  },
};
</script>

<style scoped>
.listing-panel {
  padding-top: 111px;
}

@media (max-width: 400px) {
  .listing-panel {
    padding-top: 30px;
  }
}

/* adds in the middle spacing between the two columns in full view mode */
.col-padding-rule:nth-child(even) {
  padding-right: 42px;
}
.col-padding-rule:nth-child(odd) {
  padding-left: 42px;
}

/* nothing found */
.nothingFoundPanel {
  text-align: center;
  color: #6e7c87;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 100px;
}
.nothingFoundPanel .fas {
  font-size: 120px;
  padding: 50px;
}
</style>
