<template>
  <div class="searchPanel">
    <input
      type="text"
      class="searchBox"
      placeholder="Search..."
      v-model="typed"
      v-debounce:80="typedStuff"
    />
    <i class="searchIcon fas fa-search"></i>
    <i
      v-if="typed.length > 0"
      @click="clearSearch()"
      class="clearIcon fas fa-times"
    ></i>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Search",
  data() {
    return {
      typed: "",
    };
  },
  methods: {
    // when user clicks the clear search x
    clearSearch() {
      this.typed = "";
      this.$store.dispatch("search", {
        searchTerm: "",
        startIndex: 0,
      });
    },
    typedStuff() {
      // are we in the right route?
      if (this.$router.currentRoute.name !== "Listing") {
        this.$router.push({ name: "Listing" });
      } else {
        // request to search items
        this.$store.dispatch("search", {
          searchTerm: this.typed,
          startIndex: 0,
        });
      }
    },
  },
  computed: mapState(["searchTerm"]),
  watch: {
    searchTerm: function() {
      // revert ourselves if some other part of the app decided to change the search term.
      if (this.searchTerm.length == 0 && this.typed.length !== 0) {
        this.typed = "";
      }
    },
  },
};
</script>

<style scoped>
.searchPanel {
  width: 100%;
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
}
.searchBox {
  width: 100%;
  height: 50px;
  background-color: #23292f;
  border: 2px solid #23292f;
  outline: none;
  border-radius: 50px;
  padding-left: 70px;
  color: #bfcad7;
}

.searchBox:focus {
  border: 2px solid #3a4f5f;
}

.searchBox::placeholder {
  color: #505a61;
}

.searchIcon,
.clearIcon {
  position: absolute;
  top: 52px;
  color: #505a61;
  font-size: 27px;
}

.searchIcon {
  left: 20px;
}

.clearIcon {
  right: 30px;
  cursor: pointer;
  font-size: 17px;
  top: 56px;
}
</style>
