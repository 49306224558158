<template>
  <div>
    <!-- full field -->
    <div class="dropdownWidget" :class="{ openField: open == true }">
      <!-- selected value -->
      <div
        @click="fieldClick()"
        class="selectedValuePanel"
        :class="{
          'selectedValuePanel-icon-mode':
            settings !== undefined && settings.iconMode == true,
        }"
      >
        <!-- show what has been selected -->
        <span
          v-if="selectedOptionText !== undefined"
          v-html="selectedOptionText"
        ></span>
        <!-- show placeholder if we got it -->
        <span
          v-else-if="
            selectedOptionText == undefined &&
              settings !== undefined &&
              settings.placeholder !== undefined
          "
          v-html="settings.placeholder"
        ></span>
        <!-- generic placeholder -->
        <span v-else>Specify</span>

        <!-- dropdown icon -->
        <i class="fas fa-angle-down"></i>
      </div>

      <!-- dropdown panel -->
      <div
        v-if="open == true"
        class="dropdownPanel"
        :class="{
          'dropdownPanel-icon-mode':
            settings !== undefined && settings.iconMode == true,
        }"
      >
        <!-- search -->
        <div class="searchPanel">
          <!-- searchbox -->
          <input
            v-model="typed"
            type="text"
            class="searchBox"
            ref="searchBox"
            @keydown="searchKeyDown"
          />

          <!-- search icon -->
          <i class="fas fa-search searchIcon"></i>

          <!-- close icon -->
          <i
            v-if="typed.length > 0"
            @click="clearSearch()"
            class="fas fa-times closeIcon"
          ></i>
        </div>

        <!-- options -->
        <vue-custom-scrollbar
          class="optionsPanel"
          :class="{
            'optionsPanel-icon-mode':
              settings !== undefined && settings.iconMode == true,
          }"
        >
          <div
            v-for="(opt, index) of optionList"
            :key="opt.id"
            class="option"
            :class="{
              'option-selected': index == selectedIndex,
              'option-icon-mode':
                settings !== undefined && settings.iconMode == true,
            }"
            @mouseover="hoverOption(index)"
            @click="clickOnOption(opt)"
            v-html="opt.name"
          ></div>
        </vue-custom-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import { mapState } from "vuex";

export default {
  name: "Dropdown",
  props: ["value", "options", "settings"],
  components: { vueCustomScrollbar },
  model: {
    event: "modified",
  },
  data() {
    return {
      open: false,
      typed: "",
      selectedIndex: 0,
      uid: undefined,
    };
  },
  created() {
    this.uid = new Date().getTime() + "" + Math.random() * 10000000000;
  },
  computed: {
    // map a list of options filtered by what the user has typed
    optionList() {
      return this.options.filter((x) => {
        return (
          // nothiong typed, return everything
          this.typed === "" ||
          // typed text shows up in the visible text section of the option
          x.name.toLowerCase().includes(this.typed.toLowerCase()) ||
          // typed text is contained within a helper tag for the option
          (x.tags !== undefined &&
            x.tags.toLowerCase().includes(this.typed.toLowerCase()))
        );
      });
    },
    selectedOptionText() {
      let opt = this.options.find((o) => {
        return o.id === this.value;
      });
      if (opt !== undefined) {
        return opt.name;
      }
      return undefined;
    },
    ...mapState(["open_dropdown"]),
  },
  watch: {
    // watch which dropdown is open, and if it's not us, close ours
    open_dropdown: function() {
      if (this.open_dropdown !== undefined && this.open_dropdown !== this.uid) {
        this.hideDropdown();
      }
    },
  },
  methods: {
    changeValue(newVal) {
      this.$emit("modified", newVal);
    },
    showDropdown() {
      // clear search and open our dropdown
      this.open = true;
      this.clearSearch();

      // let other dropdowns know that we are now open, they should close via watch
      this.$store.dispatch("setOpenDropdown", { id: this.uid });

      // we delay the focus, DOM needs some time to render the element first
      setTimeout(() => this.$refs.searchBox.focus(), 200);

      // start listening for click events so we can close dialog click is outside
      document.addEventListener("click", this.globalClick);
    },
    hideDropdown() {
      this.open = false;

      // stop listening for events
      document.removeEventListener("click", this.globalClick);
    },
    globalClick(e) {
      // see if the click path contains our dropdown
      let found = false;
      for (var i = 0; i < e.path.length; i++) {
        if (
          e.path[i] !== undefined &&
          e.path[i].className !== undefined &&
          (e.path[i].className.includes("dropdownPanel") ||
            e.path[i].className.includes("dropdownWidget"))
        ) {
          found = true;
        }
      }
      // if click it outside of our dropdown panel, hide ourselves
      if (found == false) {
        this.hideDropdown();
      }
    },
    // clicked on the selected value, toggle the dropdown.
    fieldClick() {
      if (this.open) {
        this.hideDropdown();
      } else {
        this.showDropdown();
      }
    },
    // clearing search and index
    clearSearch() {
      this.typed = "";
      this.selectedIndex = 0;
    },
    // arrows and enter keys handler
    searchKeyDown(e) {
      // down arrow
      if (e.keyCode == 40) {
        this.selectedIndex = Math.min(
          this.optionList.length - 1,
          this.selectedIndex + 1
        );
      }
      // up arrow
      else if (e.keyCode == 38) {
        this.selectedIndex = Math.max(0, this.selectedIndex - 1);
      }
      // enter key
      else if (e.keyCode == 13) {
        this.changeValue(this.optionList[this.selectedIndex].id);
        this.hideDropdown();
      }
      // escape key
      else if (e.keyCode == 27) {
        this.hideDropdown();
      }
      // otherwise reset the index
      else {
        this.selectedIndex = 0;
      }
    },
    // mouse hovers over options
    hoverOption(index) {
      this.selectedIndex = index;
    },
    // use clicks on option
    clickOnOption(option) {
      this.changeValue(option.id);
      this.hideDropdown();
    },
  },
};
</script>

<style scoped>
/* all encompassing element */
.dropdownWidget {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

/* the final choice that's visible */
.selectedValuePanel {
  position: relative;
  padding-top: 10px;
  padding-left: 20px;
}
.selectedValuePanel .fas {
  color: #505a61;
  padding-left: 4px;
  font-size: 26px;
  vertical-align: middle;
}
.selectedValuePanel-icon-mode {
  font-size: 56px;
}

/* modifier for panel when we open the dropdown */
.openField {
  background-color: #242a30;
  border-radius: 15px 15px 0px 0px;
}

/* panel containing searchbox + options */
.dropdownPanel {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 40px;
  background-color: #242a30;
  border-radius: 0px 0px 15px 15px;
  z-index: 10;
}
.dropdownPanel-icon-mode {
  top: 94px;
}

/* panel contianing search field + icon */
.searchPanel {
  position: relative;
  padding-top: 10px;
}

/* search icon */
.searchPanel .searchIcon,
.searchPanel .closeIcon {
  position: absolute;
  left: 12px;
  top: 19px;
  color: #505a61;
  font-size: 18px;
}

/* clear search box */
.searchPanel .closeIcon {
  left: auto;
  right: 15px;
}

/* search input element */
.searchBox {
  border: none;
  outline: none;
  width: 100%;
  background-color: #20262c;
  border-radius: 10px;
  height: 36px;
  line-height: 36px;
  color: #929da5;
  padding-left: 40px;
  font-size: 15px;
}

/* panel contianing options, either rows or icons */
.optionsPanel {
  color: #798891;
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  padding-left: 10px;
  max-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.optionsPanel-icon-mode {
  display: flex;
  flex-wrap: wrap;
}

/* options in the dropdown */
.option {
  padding-bottom: 5px;
}
.option-selected {
  color: #bfcad7;
}
.option-icon-mode {
  padding-bottom: 5px;
  font-size: 30px;
  width: 48px;
  text-align: center;
}
</style>
