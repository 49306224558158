<template>
  <div>
    <!-- actual note -->
    <div class="columns">
      <!-- left padding -->
      <div class="column col-2 col-xl-2 col-sm-1"></div>

      <!-- single column mode -->
      <div class="column col-8  col-xl-8 col-sm-10">
        <div class="genericPanel">
          <div class="genericTitle">Sitemap</div>
          <ul>
            <li v-for="item in listing" :key="item.key">
              <router-link
                class="noteLink"
                :to="{ name: 'OneNote', params: { notekey: item.key } }"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- right padding -->
      <div class="column col-2 col-xl-2 col-sm-1"></div>
    </div>
  </div>
</template>

<script>
import NoteService from "@/service/NoteService";

export default {
  name: "SiteMap",
  data() {
    return {
      listing: [],
    };
  },
  created() {
    NoteService.getListing().then((response) => {
      this.listing = response.data;
    });
  },
};
</script>

<style scoped>
.noteLink {
  color: #bfcad7;
  font-size: 13px;
}
</style>
