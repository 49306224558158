import Vue from "vue";
import Vuex from "vuex";

import NoteService from "@/service/NoteService";
import * as columns from "@/store/modules/columns";
import * as login from "@/store/modules/login";
import * as changes from "@/store/modules/changes";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    languages: [],
    icons: [],
    notes: [],
    singleNote: {},
    lastFetchAmount: undefined,
    searchTerm: "",
    copied_noteKey: undefined,
    linked_noteKey: undefined,
    open_dropdown: 77,
    save_code: undefined,
    listing_visited: false,
  },
  mutations: {
    SET_LANGUAGUES(state, languages) {
      state.languages = languages;
    },
    SET_ICONS(state, icons) {
      state.icons = icons;
    },
    SET_NOTES(state, notes) {
      state.notes = notes;
    },
    SET_LAST_FETCH_AMOUNT(state, lastFetchAmount) {
      state.lastFetchAmount = lastFetchAmount;
    },
    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    SET_SINGLE_NOTE(state, singleNote) {
      state.singleNote = singleNote;
    },
    SET_COPIED_NOTE_KEY(state, copied_noteKey) {
      state.copied_noteKey = copied_noteKey;
    },
    SET_LINKED_NOTE_KEY(state, linked_noteKey) {
      state.linked_noteKey = linked_noteKey;
    },
    SET_OPEN_DROPDOWN(state, open_dropdown) {
      state.open_dropdown = open_dropdown;
    },
    SET_SAVE_CODE(state, save_code) {
      state.save_code = save_code;
    },
    SET_LISTING_VISITED(state, listing_visited) {
      state.listing_visited = listing_visited;
    },
  },
  actions: {
    // get the language settings
    fetchLanguages({ commit }) {
      return NoteService.getLanguages()
        .then((response) => {
          commit("SET_LANGUAGUES", response.data);
        })
        .catch((err) => console.log(err));
    },

    // get the icon settings
    fetchIcons({ commit }) {
      return NoteService.getIcons()
        .then((response) => {
          commit("SET_ICONS", response.data);
        })
        .catch((err) => console.log(err));
    },

    // mark that we've visited the listing page
    // this is useful for going back
    visitedListing({ commit }) {
      commit("SET_LISTING_VISITED", true);
    },

    // clear out all the notes
    clearNotes({ commit }) {
      commit("SET_NOTES", []);
    },

    // trim the notes to just 15 (useful when column switching)
    trimNotes({ commit }) {
      commit(
        "SET_NOTES",
        this.state.notes.splice(0, Math.min(15, this.state.notes.length))
      );
    },

    // remember when dropdown is open so they don't overlap
    setOpenDropdown({ commit }, { id }) {
      commit("SET_OPEN_DROPDOWN", id);
    },

    // remember which note we clicked "copy to clipboard" to
    setCopiedNoteKey({ commit }, { copied_noteKey }) {
      commit("SET_LINKED_NOTE_KEY", undefined);
      commit("SET_COPIED_NOTE_KEY", copied_noteKey);
    },

    // remember which note we clicked "copy link" to
    setLinkedNoteKey({ commit }, { linked_noteKey }) {
      commit("SET_COPIED_NOTE_KEY", undefined);
      commit("SET_LINKED_NOTE_KEY", linked_noteKey);
    },

    // get a single note
    getSingleNote({ commit }, { id }) {
      return NoteService.getSingleNote(id)
        .then((response) => {
          commit("SET_SINGLE_NOTE", response.data);

          // dynamic title for the note
          document.title = "Membug - " + response.data.title;
        })
        .catch((err) => console.log(err));
    },

    // for adding new notes
    setBlankNote({ commit }) {
      commit("SET_SINGLE_NOTE", { note: "// some code" });
    },

    // get a single note
    saveNote({ commit }, { note }) {
      return NoteService.saveNote(note)
        .then((response) => {
          let escapedCode = response.data.replace(/(\r\n|\n|\r)/gm, "");
          commit("SET_SAVE_CODE", escapedCode);
        })
        .catch((err) => console.log(err));
    },

    // when editing notes, forget the past status
    clearSaveCode({ commit }) {
      commit("SET_SAVE_CODE", undefined);
    },

    // get the icon settings
    search({ commit, dispatch }, { searchTerm, startIndex }) {
      // generic title
      document.title = "Membug";

      // remember what we searched for
      commit("SET_SEARCH_TERM", searchTerm);

      // if we are asking for more stuff but the last batch was empty, don't ask again
      if (this.state.lastFetchAmount == 0 && startIndex > 0) {
        return;
      }
      return NoteService.search(searchTerm, startIndex)
        .then((response) => {
          // record the size of the data coming in
          commit("SET_LAST_FETCH_AMOUNT", response.data.length);

          // fresh search at the top, just assume the first 15 that come back
          if (startIndex == 0) {
            commit("SET_NOTES", response.data);
          }
          // merge in the new 15 into existin list
          else {
            commit("SET_NOTES", [...this.state.notes, ...response.data]);
          }

          // is the final result is just one note?
          dispatch("columns/setOneResult", {
            one_result: this.state.notes.length == 1,
          });
        })
        .catch((err) => console.log(err));
    },
  },
  modules: { columns, login, changes },
});
