import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";

// copy into clipboard
import Clipboard from "v-clipboard";
Vue.use(Clipboard);

// language
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/php/php.js";
import "codemirror/mode/shell/shell.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/mode/ruby/ruby.js";
import "codemirror/mode/python/python.js";

// theme css
import "@/assets/onedark.css";
import "@/assets/onedark-java.css";

// scroll
import "codemirror/addon/scroll/simplescrollbars.js";
import "codemirror/addon/scroll/simplescrollbars.css";

// mark selection
import "codemirror/addon/selection/mark-selection.js";

// debounce
import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

// cookies
Vue.use(require("vue-cookies"));

// regular stuff below
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
