import axios from "axios";

const apiClient = axios.create({
  //   baseURL: "http://localhost:8080/membug/",
  baseURL: "https://www.membug.com/membug/",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getChanges() {
    return apiClient.get("/changes");
  },
  accept(id) {
    return apiClient.post("/accept", id);
  },
  reject(id) {
    return apiClient.post("/reject", id);
  },
};
