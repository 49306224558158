<template>
  <div>
    <!-- actual note -->
    <div class="columns">
      <!-- left padding -->
      <div class="column col-4 col-xl-2 col-sm-1"></div>

      <!-- single column mode -->
      <div class="column col-4  col-xl-8 col-sm-10">
        <div class="genericPanel">
          <!-- logo -->
          <div class="logoPanel">
            <img class="logo" src="../assets/logoBig.png" alt="large logo" />
          </div>

          <!-- warning panel -->
          <div v-if="loginFailed" class="warningPanel">
            <div class="warningIcon">
              <i class="fas fa-lock"></i>
            </div>
            <div class="warningText">
              Wrong username or password.
            </div>
          </div>

          <!-- Username -->
          <div class="fieldPanel" ref="usernameWrapper">
            <input
              v-model="username"
              type="text"
              class="field"
              placeholder="Username"
              @keydown="typedSomething"
            />
            <i class="fas fa-user"></i>
          </div>
          <!-- Password -->
          <div class="fieldPanel" ref="passwordWrapper">
            <input
              v-model="password"
              type="password"
              class="field"
              placeholder="Password"
              @keydown="typedSomething"
            />
            <i class="fas fa-lock"></i>
          </div>
          <button class="genericButton" @click="login()">Login</button>
        </div>
      </div>

      <!-- right padding -->
      <div class="column col-4 col-xl-2 col-sm-1"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      validationSuccess: undefined,
    };
  },
  computed: {
    test() {
      return false;
    },
    ...mapState({ loginFailed: (state) => state.login.loginFailed }),
  },
  methods: {
    // someone typed something, validate stuff
    typedSomething(e) {
      // enter key, try to login
      if (e.keyCode == 13) {
        this.login();
        return;
      }

      // otherwise see if we need to validate
      // only if the validation failed in the past, will we validate after typing
      if (this.validationSuccess == false) {
        this.validate();
      }
    },

    // both fields should be filled in
    validate() {
      let ok = true;

      // remove all
      this.$refs.usernameWrapper.classList.remove("vfail");
      this.$refs.passwordWrapper.classList.remove("vfail");

      // login
      if (this.username.length < 3) {
        this.$refs.usernameWrapper.classList.add("vfail");
        ok = false;
      }
      //password
      if (this.password.length < 3) {
        this.$refs.passwordWrapper.classList.add("vfail");
        ok = false;
      }
      this.validationSuccess = ok;
      return ok;
    },

    // try to login
    login() {
      // if validation is bad, bail
      if (this.validate() == false) {
        return false;
      }
      // otherwise attempt to login
      else {
        let attempt = { user: this.username, pass: this.password };
        this.$store.dispatch("login/attemptLogin", { attempt: attempt });
      }
    },
  },
};
</script>

<style scoped>
/* at less than 800, have smaller padding in notes */
@media (max-width: 960px) {
  .loginPanel {
    padding: 60px 40px;
  }
}

/* panel for the logo */
.logoPanel {
  text-align: center;
  padding-bottom: 60px;
  padding-top: 30px;
}

.warningPanel {
  background-color: #621e1e;
  color: #c64545;
  padding: 20px 40px;
  border-radius: 17px;
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
}
.warningIcon {
  flex: 0 0 50px;
}
.warningIcon .fas {
  font-size: 50px;
  vertical-align: middle;
}
.warningText {
  text-align: center;
  flex: 1;
}
</style>
