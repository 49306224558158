<template>
  <div>
    <!-- actual note -->
    <div class="edit-note-panel columns">
      <!-- left padding -->
      <div class="column col-1"></div>

      <!-- signle column mode -->
      <div class="column col-10">
        <!-- Note iteself -->
        <NoteEditor
          v-if="save_code == undefined && singleNote"
          :note="singleNote"
        />

        <!-- success panel -->
        <div class="genericPanel" v-if="save_code == 'PENDING'">
          <i class="bigIcon fas fa-check-circle"></i>
          Content saved
          <button class="genericButton backButton" @click="backToListing">
            Back to listing
          </button>
        </div>

        <!-- everything is bad panel -->
        <div
          class="genericPanel"
          v-if="save_code != undefined && save_code !== 'PENDING'"
        >
          <i class="bigIcon badIcon fas fa-minus-circle"></i>
          <span class="badLabel">
            Content rejected
          </span>
          <div class="explain">{{ getExplain }}</div>
          <button class="genericButton backButton" @click="backToEditor">
            Back to editor
          </button>
        </div>
      </div>

      <!-- right padding -->
      <div class="column col-1"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NoteEditor from "@/components/NoteEditor";

export default {
  name: "Edit",
  props: ["notekey"],
  components: { NoteEditor },
  data() {
    return {
      note: {},
    };
  },
  computed: {
    getExplain() {
      if (this.save_code == "BLOCK") {
        return "Categorized as: Blocked";
      } else if (this.save_code == "NOINFO") {
        return "Categorized as: Not enough info";
      } else if (this.save_code == "MUCHINFO") {
        return "Categorized as: Too much info";
      } else if (this.save_code == "ENGLISH") {
        return "Categorized as: Not English";
      } else if (this.save_code == "OFFENSIVE") {
        return "Categorized as: Offensive";
      } else if (this.save_code == "SPAM") {
        return "Categorized as: Spam";
      } else if (this.save_code == "TOOMANY") {
        return "Too many submissions";
      } else if (this.save_code == "NOTCODE") {
        return "Categorized as: Not code";
      } else if (this.save_code == "NOCHANGE") {
        return "No changes detected";
      }
      return this.save_code;
    },
    ...mapState(["singleNote", "save_code"]),
  },
  created() {
    this.$store.dispatch("clearSaveCode");
    if (this.notekey == "new") {
      this.$store.dispatch("setBlankNote");
    }
    // just fetch the note
    else {
      this.$store.dispatch("getSingleNote", { id: this.notekey });
    }
    window.scrollTo(0, 0);
  },
  methods: {
    // go back to the main page
    backToListing() {
      this.$router.push({ name: "Listing" });
    },
    // go back to editing
    backToEditor() {
      this.$store.dispatch("clearSaveCode");
    },
  },
};
</script>

<style scoped>
.edit-note-panel {
  padding-top: 110px;
}

/* panel for the status */
.genericPanel {
  color: #8ab06b;
  font-weight: bold;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 0px;
}

/* things are bad panel */
.badLabel {
  color: #ce4747;
  font-size: 24px;
}

/* status icon */
.bigIcon {
  color: #8ab06b;
  font-size: 120px;
  padding-bottom: 30px;
}

/* bad icon*/
.badIcon {
  color: #6e7c87;
}

.explain {
  color: #798892;
}

/* everything is good, go back to listing */
.backButton {
  color: #b4c0c7;
  background-color: #353d46;
  margin-left: 0px;
  width: 200px;
  margin-top: 66px;
}
</style>
