export const namespaced = true;

export const state = {
  col_mode: "double",
  user_choice: "double",
  one_result: false,
  narrow_page: false,
};

export const mutations = {
  SET_COL_MODE(state, col_mode) {
    state.col_mode = col_mode;
  },
  SET_USER_CHOICE(state, user_choice) {
    state.user_choice = user_choice;
  },
  SET_ONE_RESULT(state, one_result) {
    state.one_result = one_result;
  },
  SET_NARROW_PAGE(state, narrow_page) {
    state.narrow_page = narrow_page;
  },
};

export const actions = {
  // what's our final state?
  computeFinalColState({ commit }) {
    // narrow page always is one column
    if (state.narrow_page == true) {
      commit("SET_COL_MODE", "single");
    }
    // single results always show up in one column
    else if (state.one_result == true) {
      commit("SET_COL_MODE", "single");
    }
    // use whatever the users picked
    else {
      commit("SET_COL_MODE", state.user_choice);
    }
  },

  // resize listener will call this
  setNarrowPage({ commit, dispatch }, { narrowPage }) {
    commit("SET_NARROW_PAGE", narrowPage);
    dispatch("computeFinalColState");
  },
  // fetch notes will call this
  setOneResult({ commit, dispatch }, { one_result }) {
    commit("SET_ONE_RESULT", one_result);
    dispatch("computeFinalColState");
  },
  // user clicked on an option
  setUserChoice({ commit, dispatch }, { user_choice }) {
    // narrow down the list of note first

    // commit the choice
    commit("SET_USER_CHOICE", user_choice);
    dispatch("computeFinalColState");
  },
};
