<template>
  <div id="app">
    <NavBar />
    <keep-alive include="SiteMap,Listing">
      <router-view :key="$route.fullPath" />
    </keep-alive>
    <div v-if="currentScroll > 100" class="scrollHelper" @click="scrollToTop()">
      <i class="fas fa-chevron-circle-up"></i>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      innerWidth: 0,
      lastFetchMoreTimestamp: Date.now(),
      currentScroll: 0,
    };
  },
  computed: {
    temp() {
      return {};
    },
    ...mapState(["notes", "searchTerm"]),
  },
  created() {
    // compute our width
    this.innerWidth = window.innerWidth;
    this.computeNarrowOrNot();

    // add scroll and resize handlers
    window.addEventListener("resize", this.resizeHandler, { passive: true });
    window.addEventListener("scroll", this.scrollHandler, { passive: true });

    // request profile + data
    this.$store
      .dispatch("login/checkLogin")
      .then(this.$store.dispatch("fetchLanguages"))
      .then(this.$store.dispatch("fetchIcons"))
      .then(
        this.$store.dispatch("search", {
          searchTerm: "",
          startIndex: 0,
        })
      );
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    // when the window changes, check if we need to go to narrow mode or not
    resizeHandler(e) {
      this.innerWidth = e.target.innerWidth;
      this.computeNarrowOrNot();
    },

    // when the scroll gets close to the bottom, ask for more notes
    scrollHandler() {
      // this should only happen on the main listing page.
      if (this.$route.path !== "/") {
        return;
      }

      this.currentScroll = window.scrollY;

      // current scroll position + window height
      let scroll = window.scrollY + window.innerHeight;

      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      // if we have notes, and we're nearing bottom of page and we
      // haven't requested more notes in the last second or so
      if (
        this.notes.length > 0 &&
        height - scroll < 600 &&
        Date.now() - this.lastFetchMoreTimestamp > 200
      ) {
        this.lastFetchMoreTimestamp = Date.now();
        this.$store.dispatch("search", {
          searchTerm: this.searchTerm,
          startIndex: this.notes.length,
        });
      }
    },
    computeNarrowOrNot() {
      this.$store.dispatch("columns/setNarrowPage", {
        narrowPage: this.innerWidth < 1600,
      });
    },
  },
};
</script>

<style>
/* Dark style for the body */
body {
  color: #d1dde3;
  background-color: #22262c;
  font-family: "Montserrat", sans-serif;
}

ul {
  list-style: disc;
}
ol {
  list-style: disc;
}

.scrollHelper {
  cursor: pointer;
  position: fixed;
  right: 2%;
  bottom: 25px;
  color: #505b62;
  font-size: 49px;
}

.scrollHelper:hover {
  color: #b6c1ce;
}

/* dynamic height for the code while in listing*/
.listing-panel .CodeMirror {
  height: auto !important;
}

.listing-panel .CodeMirror-scroll {
  max-height: 300px;
}

/* Single mode, just full height it */
.single-note-panel .CodeMirror,
.edit-note-panel .CodeMirror {
  height: auto !important;
}

.single-note-panel .CodeMirror-scroll,
.edit-note-panel .CodeMirror-scroll {
  max-height: auto !important;
}

/* get rid of white square on scrollbar bottom */
.CodeMirror-scrollbar-filler {
  background-color: #282e35 !important;
}

.CodeMirror-overlayscroll-horizontal,
.CodeMirror-overlayscroll-vertical {
  visibility: hidden;
}

.note:hover .CodeMirror-overlayscroll-horizontal,
.note:hover .CodeMirror-overlayscroll-vertical {
  visibility: inherit;
}

a {
  box-shadow: none !important;
  text-decoration: none !important;
}

/* Color the hover scrollbar to be same as dropdown */
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: #242a30 !important;
}

/* validation */
.vfail input,
.vfail textarea {
  background-color: #942d2d !important;
}
.vfail input::placeholder,
.vfail textarea::placeholder {
  color: #4c0808 !important;
}
.vfail .fas {
  color: #6f1313 !important;
}

.bad .selectedValuePanel .fas {
  color: #c72f2f;
}

.bad .selectedValuePanel {
  color: #c72f2f;
}

.bad .cm-s-one-dark {
  border-left: 4px solid #c72f2f;
}

/* generic panel */
.genericPanel {
  margin-top: 100px;
  position: relative;
  background-color: #282e35;
  padding: 60px 100px;
  border-radius: 17px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Generic small button */
.genericButton {
  background-color: #8ab06b;
  border: none;
  color: white;
  padding: 11px 40px;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
  outline: none;
  width: 140px;
}

/* Generic title */
.genericTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
  padding-top: 22px;
  color: #bfcad7;
}

/* wrapper for the field */
.fieldPanel {
  position: relative;
  padding-bottom: 20px;
  width: 100%;
}
.fieldPanel .fas {
  position: absolute;
  top: 14px;
  left: 23px;
  font-size: 26px;
  color: #505a61;
}

/* username and password styling */
.field {
  width: 100%;
  background-color: #23292f;
  height: 55px;
  line-height: 55px;
  border: none;
  outline: none;
  border-radius: 50px;
  color: #d1dde3;
  padding-left: 70px;
}
.field::placeholder {
  color: #505a61;
}

.CodeMirror-overlayscroll-horizontal div,
.CodeMirror-overlayscroll-vertical div {
  position: absolute;
  background: #46525d;
  border-radius: 3px;
}

/* Allow some flexibility into re-ordering items depending on the screen size */
@media (max-width: 480px) {
  .col-xs-order-1 {
    order: 1;
  }

  .col-xs-order-2 {
    order: 2;
  }
  .col-xs-order-3 {
    order: 3;
  }
}

@media (max-width: 600px) {
  .col-sm-order-1 {
    order: 1;
  }
  .col-sm-order-2 {
    order: 2;
  }
  .col-sm-order-3 {
    order: 3;
  }
}

@media (max-width: 840px) {
  .col-md-order-1 {
    order: 1;
  }
  .col-md-order-2 {
    order: 2;
  }
  .col-md-order-3 {
    order: 3;
  }
}

@media (max-width: 960px) {
  .col-lg-order-1 {
    order: 1;
  }
  .col-lg-order-2 {
    order: 2;
  }
  .col-lg-order-3 {
    order: 3;
  }
}

@media (max-width: 1280px) {
  .col-xl-order-1 {
    order: 1;
  }
  .col-xl-order-2 {
    order: 2;
  }
  .col-xl-order-3 {
    order: 3;
  }
}
</style>
