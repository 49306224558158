<template>
  <div class="footerDiv">
    <div class="footer">
      <router-link :to="{ name: 'Terms' }">Terms of Service</router-link>
      <span class="spacer">|</span>
      <router-link :to="{ name: 'SiteMap' }">Sitemap</router-link>
      <span class="copy">©{{ getYear }} Membug</span
      ><img
        class="logo"
        src="../assets/logoSmallDark.png"
        @click="logoClick()"
        alt="Footer logo"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    getYear() {
      return new Date().getYear() + 1900;
    },
    methods: {
      logoClick() {
        window.scrollTo(0, 0);
      },
    },
  },
};
</script>

<style scoped>
.footerDiv {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  background-color: #282e35;
  color: #798892;
  padding: 12px 40px;
  border-radius: 50px;
  font-size: 14px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.spacer {
  padding-left: 10px;
  padding-right: 10px;
}

.copy {
  color: #505a61;
  padding-left: 30px;
  padding-right: 15px;
}

a {
  color: #798892;
}
</style>
